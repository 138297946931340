@import url("https://db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text");
@import url("https://fonts.googleapis.com/css?family=Inter");
@import url("https://db.onlinewebfonts.com/c/7615af7a9f681395bf515be718f1bf9c?family=SofiaPro");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee");

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a.linkyellow1 {
  color: #ffb400;
  text-decoration: none;
}

body {
  font-size: 14px;
  font-weight: 400;
  background: #1e191c;
  color: #fff;
}

header a.signbtn {
  border-radius: 3px;
  background: #323232;
  color: #fff;
  padding: 5px 8px;
  font-size: 16px;
  font-weight: 600;
  font-family: "SofiaPro";
}

#main h1 {
  font-family: "Inter";
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px; /* 106.25% */
  letter-spacing: -4.16px;
}

#main h1 span {
  background: linear-gradient(90deg, #ffb400 0%, #ff8b00 55.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headingtxt {
  position: absolute;
  top: 30%;
  left: 0;
}

#main h6 {
  font-family: "SF Pro Text";
  font-size: 20px;
  font-weight: 400;
}

#main a.probtn {
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
  background: #323232;
  padding: 16px 30px;
  color: #fff;
  font-family: "SofiaPro";
}

.yellowtext {
  color: #ff9800 !important ;
}

/************ Login Page ******************/

.welcard {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 1px solid #43403e;
  background: #272223;
}
.card-list {
  border: 1px solid #43403e;
  background: white;
  border-radius: 3px;
  color: #fff;
}
.uploadcard {
  align-items: center;
  justify-content: center;
  background: #676767;
  color: white;
 
  min-height: 278px;
  border-radius: 10px;
  max-width: 312px
}
.googlecard {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top: 0px solid #43403e;
  border-left: 1px solid #43403e;
  border-right: 1px solid #43403e;
  border-bottom: 1px solid #43403e;
  background: #272223;
}
.nfc-card {
height: 183px;
 width: 320px;
 border-radius: 10px;
 padding: 1px;
 background: black
}

.welcard input.form-control {
  border-radius: 5px;
  border: 1px solid #454343;
  background: #211f1e;
  backdrop-filter: blur(10px);
}

.form-control::placeholder {
  color: #696867;
  font-family: "Inter";
}

.loginbtn {
  border-radius: 5px;
  background: #fff;
  backdrop-filter: blur(10px);
  color: #141517;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  display: block;
  padding: 5px;
  text-align: center;
}

.googlebtn {
  border-radius: 5px;
  background: #383433;
  backdrop-filter: blur(10px);
  color: #fff;
  font-family: "ABeeZee";
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  display: block;
  padding: 5px;
  text-align: center;
}

footer .line {
  border-top: 1px solid #323232;
}

footer a.nav-link {
  color: #969696;
  font-family: "SF Pro Text";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.91px;
  padding: 10px 25px 10px 0px;
}

footer a:hover.nav-link,
#main a:hover.probtn,
header a:hover.signbtn {
  color: #ffb400;
}

footer a.nav-link1 {
  color: #969696;
  font-family: "SF Pro Text";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.91px;
  padding-right: 0px;
}

footer a.nav-link i {
  font-size: 20px;
}

/******************* shop vyldcard Page css *************************/

#shopvyldcard {
  margin-top: 200px;
}

#shopvyldcard h1 {
  font-size: 64px;
  letter-spacing: -6.5%;
}
#shopvyldcard h2,
#faqs h2 {
  font-size: 40px;
}

#shopvyldcard h6 {
  font-size: 20px;
}

a.regbtn {
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
  background: #272223;
  padding: 16px 30px;
  border: 1px solid #43403e;
  color: #fff;
  font-family: "SofiaPro";
}

#shopvyldcard p {
  font-family: "SF Pro Text";
}

.f20 {
  font-size: 20px;
}
.f16 {
  font-size: 16px;
}

#shopvyldcard .vyldcard {
  position: relative;
}

#shopvyldcard .mboncard {
  position: absolute;
  top: 20%;
  left: 32%;
}

#shopvyldcard .card {
  background-color: #272223;
  border-radius: 0;
  text-align: center;
  padding: 100px 60px;
}

#shopvyldcard .card img {
  display: block;
  margin: 0 auto; /* Center the image horizontally and add margin below */
  max-width: 100%;
}

.greybox {
  background-color: #969696;
  color: #fff;
  padding: 50px 30px 50px 40px;
  position: relative;
  top: -202px;
}

/********** Accordian ********************/
#faqs {
  margin-top: -200px;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  background: #1e191c;
  color: #fff;
  padding: 6px 0px;
  font-size: 16px;
}

.accordion-item {
  border-bottom: 1px dotted #454343 !important;
  border-top: 1px dotted #454343 !important;
  color: #fff;
  background-color: #1e191c;
}

.accordion-collapse {
  border: 0;
}

.accordion-button:focus {
  box-shadow: none;
  border: none;
}
.accordion-button:not(.collapsed) {
  background: none;
  color: #fff;
}
.accordion-body {
  padding: 10px 0px 15px 0px;
  font-size: 14px;
}
.accordion-button::after {
  width: auto;
  height: auto;
  content: "+";
  font-size: 30px;
  background-image: none;
  font-weight: 100;
  color: #bcbcbc;
  transform: translateY(-4px);
}
.accordion-button:not(.collapsed)::after {
  width: auto;
  height: auto;
  background-image: none;
  content: "-";
  font-size: 38px;
  transform: translate(-5px, -4px);
  transform: rotate(0deg);
}

/******************* End of shop vyldcard Page css *************************/
#uploadesigncard {
  color: #fff;
}

#uploadesigncard h2 {
  font-size: 40px;
  letter-spacing: -6.5%;
}

.yellowtext2 {
  color: #ffb400;
  font-size: 23px;
}

#uploadesigncard .card {
  background-color: #272223;
  border-radius: 0;
  border: 1px solid #454343;
  border-right: 0;
  color: #fff;
  height: 360px;
}

#uploadesigncard .card2 {
  background-color: #272223;
  border-radius: 0;
  border: 1px solid #454343;
  color: #fff;
  height: 360px;
}

#uploadesigncard .card h6 {
  font-size: 18px;
}

.f18 {
  font-size: 18px;
}
.f14 {
  font-size: 14px;
}
.f12 {
  font-size: 12px;
}

#uploadesigncard .card i {
  font-size: 45px;
}

#logindetails a {
  color: #fff;
}

.cart {
  position: relative;
  display: block;
  width: 28px;
  height: 28px;
}

.crtxt {
  position: relative;
  top: -17px;
  left: 33px;
}

#help img {
  position: relative;
  top: 13px;
  left: 0px;
}

.cart .count {
  position: absolute;
  top: -5px;
  right: 0;
  z-index: 2;
  font-size: 11px;
  border-radius: 50%;
  background: white;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  text-align: center;
  color: #000;
}

.vldcard {
  position: relative;
}

.likeicon {
  position: absolute;
  top: -10px;
  right: -9px;
}

.namewifi {
  position: absolute;
  bottom: 28px;
  right: 37px;
}

.photowifi {
  position: absolute;
  bottom: 28px;
  left: 17px;
}

.namewifi img {
  vertical-align: inherit;
}

.uplodbtn {
  padding: 60px 0px 0px 40px;
}

.fylowtxt {
  color: #ffb400;
}

#vyldfyrecard a.whtlinks {
  color: #fff;
  font-size: 12px;
}

.bordrbtm {
  border-bottom: 1px solid #5a5859;
  padding-bottom: 35px;
}

#vyldfyrecard a.whtlinks:hover {
  color: #75f967;
}

#vyldfyrecard a.rounded-pill {
  background-color: #272223;
  color: #fff;
  padding: 10px 36px;
  border: 1px solid #43403e;
  border-radius: 50rem;
}

#vyldfyrecard .bordotted {
  border: 1px dotted #d9d9d9;
  position: absolute;
  top: 30px;
  left: 30px;
  width: 92.5%;
  height: 87%;
}

#vyldfyrecard .wifi {
  position: absolute;
  bottom: 10%;
  right: 7%;
  font-size: 24px;
}

#vyldfyrecard .card {
  background-color: #272223;
  border-radius: 0;
  border: 1px solid #454343;
  color: #fff;
  font-size: 12px;
}
#vyldfyrecard .card:hover {
  border: 1px solid #ffb100;
}

#vyldfyrecard ul.iconlinks {
  position: relative;
  left: -100px;
}

#vyldfyrecard .img-edit {
  position: relative;
  left: 30px;
}

#personalcard .vldcardbg {
  /* background: url(./images/cardbg.png); */
  background-size: cover;
  padding: 40px;
  position: relative;
}

#personalcard .wifi {
  position: absolute;
  bottom: 17%;
  right: 10%;
  font-size: 24px;
  text-transform: uppercase;
}

#personalcard .card {
  background-color: #272223;
  border-radius: 0;
  border: 1px solid #454343;
  color: #fff;
  border-left: 0;
  font-size: 12px;
  height: 100%;
  padding: 30px;
}

#personalcard ul {
  position: relative;
  list-style-type: none;
  padding-left: 20px;
  line-height: 25px;
}

#personalcard ul li::before {
  position: absolute;
  content: "";
  left: -6px;
  margin-top: 4px;
  /* background-image: url(./images/check.png); */
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
}
.alert {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1100;
  padding: 0.8rem;
  opacity: 0.9;
  content: "\2713";
  background: #34a853;
  border-color: #189b0c;
  color: #fff;
}
.alert-danger {
  content: "f00d";
  background: #ff3443;
  border-color: #da0000;
  color: #fff;
}
.alert-info {
  content: "ℹ";
  background: #40d3dd;
  border-color: #25a1aa;
  color: #fff;
}
.passwordToggle {
  position: absolute;
  top: 44%;
  left: 28%;
  /* Adjust the distance from the right edge */
  /* transform: translateY(-50%); */

  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: 11%;
  cursor: pointer;
  /* float: inline-end; */
  right: 10px;
}
#personalcard .checkboxtxt {
  position: absolute;
  bottom: 20px;
  left: 40px;
}

#personalcard input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

#personalcard label {
  position: relative;
  cursor: pointer;
}

#personalcard label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #969696;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

#personalcard input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/********** Dash board css *********************/

#dashbrdetails ul li {
  border-right: 1px solid #454343;
  padding: 0px 30px;
  height: 18px;
}

#dashbrdetails ul li:last-child {
  border-right: 0px solid #454343;
  padding-right: 0px;
}

#dashbrdetails a {
  color: #fff;
}

.dashboard ul.nav li {
  padding: 10px 0px;
}

.dashboard ul.nav li a {
  color: #fff;
  padding: 10px 0px;
}

.dashboard h1 {
  font-family: "SF Pro Text";
  font-size: 40px;
}

.dashboard h6 {
  font-family: "SF Pro Text";
  font-size: 16px;
}

.form-item {
  position: relative;
  margin-bottom: 15px;
  font-family: "ABeeZee";
}
.form-item input,
.form-item select {
  display: block;
  width: 100%;
  height: 64px;
  border: 1px solid #454343;
  background: #211f1e;
  transition: all 0.3s ease;
  border-radius: 8px;
  padding-left: 11px;
  color: #fff;
}

.form-item input:focus-visible {
  border: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  margin-left: 12px;
}
.form-item input:focus,
.form-item select:focus {
  border: solid 1px #ccc !important;
}
.table {
  /* --bs-table-color-type: initial; */
  /* --bs-table-bg-type: initial; */
  /* --bs-table-color-state: initial; */
  /* --bs-table-bg-state: initial; */
  --bs-table-color: #ffffff;
  --bs-table-bg: #1e191c;
  --bs-table-border-color: #697076;
  --bs-table-accent-bg: transparent;
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: #4c3131;
  --bs-table-hover-bg: #212529;
  width: 100%;
  margin-bottom: 4rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.form-item textarea {
  display: block;
  width: 100%;
  height: 100px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all 0.3s ease;
  border-radius: 8px;
  padding-left: 5px;
}

.form-item label {
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 20px;
  left: 2px;
  font-size: 12px;
  background: transparent;
  padding: 0 10px;
  color: #bcbcbc;
  transition: all 0.3s ease;
}
.form-item input:focus + label,
.form-item input:valid + label,
.form-item select:focus + label,
.form-item select:valid + label,
.form-item textarea:focus + label,
.form-item textarea:valid + label {
  font-size: 12px !important;
  top: 6px;
  color: #bcbcbc;
  outline: none;
}

.colorboxpick {
  border: 1px solid #969696;
  border-radius: 2px;
  height: 21px;
  width: 40px;
  text-align: center;
  /* position: absolute; */
  margin-right: 10px;
  margin-bottom: 30px;
  top: 14px;
  right: 40px;
}

.bgblk {
  background: #000;
}

.bgylw {
  background: #ffb400;
  color: #fff;
}

.colorboxpick i {
  padding-top: 10px;
}

a.uploadbtn {
  border-radius: 7px;
  border: 1px solid #454343;
  background: #211f1e;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-family: "SF Pro Text";
  padding: 10px 0px 30px 0px;
}

a.uploadbtn i {
  font-size: 40px;
  position: relative;
  top: 8px;
  padding-right: 10px;
}

#backcarddtls .checkboxtxt {
}

#backcarddtls input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

#backcarddtls label {
  position: relative;
  cursor: pointer;
  font-family: "ABeeZee";
  font-size: 12px;
}

#backcarddtls label:before {
  content: "";
  -webkit-appearance: none;
  border: 1px solid #454343;
  background: #211f1e;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
}

#backcarddtls input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

a.cansbtbtns {
  border-radius: 7px;
  border: 1px solid #454343;
  background: #211f1e;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-family: "SF Pro Text";
  padding: 20px 42px;
}

.cardbtn {
  border: 1px solid #969696;
  border-radius: 2px;
  background: #222427;
  height: 80px;
  width: 80px;
  text-align: center;
  /* position: absolute; */
  margin-right: 10px;
  margin-bottom: 30px;
  top: 14px;
  right: 40px;
}

.cardbtn img {
  height: auto;
  width: 100%;
}
.sidebar-right {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 306px;
  background-color: #222427;
  border-left: 1px solid #5a5859;
  padding: 20px;
}

.sidebar-content {
  padding: 6px;
}

.backdesigncard {
  height: 101px;
  width: 130px;
  background: #000000;
}
/* Adjust as needed */
.design-card-container {
  margin-top: 75px;
}
.canvas {
  justify-self: center;
  border-radius: 10px;
}
.vertical-rule {
  position: absolute;
  top: 13%;
  bottom: 0%;
  left: -60px;
  border-left: 1px solid #ffffff;
  display: flex;
  align-items: center;
}

.horizontal-rule {
  position: absolute;
  top: 110%;
  left: -3px;
  right: -2px;
  border-top: 1px solid #ffffff;
  display: flex;
  justify-content: center;
}

.vertical-rule p {
  margin: 0; /* Remove default margins */
  transform: rotate(-90deg); /* Rotate the paragraph */
  transform-origin: center; /* Set the rotation origin to the center */
}
.horizontal-rule p {
  margin: 0; /* Remove default margins */
}

.canvas-image {
  position: absolute;
  top: 50px;
  left: 113px;
  z-index: 1;
  min-width:640px;
  min-height: 365px;
}
.canvas-image2 {
  position: absolute;
  top: 0px;
  left:132px;
  z-index: 1;
  min-width:640px;
  min-height: 365px;
}
.nfc-icon-placement {
  position: absolute;
  top: 0px;
  right: -41px
}
.confirm {
  border: 1px solid #454343 ;

}
.confirmcheckbox {
 border: 1px solid #454343 ;
  background: #272223;
}
.imagebox {
  border: 1px solid #454343 ;
  
}
.completecard {
  
  width: 70%;
  margin-left: 68px;
  padding: 10px;
}
.shoppingBag {
  
  color: #ffb100
}
.products {
  background: #211F1E;
  border: 1px solid #454343
}
#products .table {
  
    /* --bs-table-color-type: initial; */
    /* --bs-table-bg-type: initial; */
    /* --bs-table-color-state: initial; */
    /* --bs-table-bg-state: initial; */
    --bs-table-color: #ffffff;
    --bs-table-bg: #211f1e;
    --bs-table-border-color: #454343;
    --bs-table-accent-bg: transparent;
    --bs-table-active-color: var(--bs-emphasis-color);
    --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
    --bs-table-hover-color: #4c3131;
    --bs-table-hover-bg: #212529;
    width: 100%;
    /* margin-bottom: 4rem; */
    vertical-align: top;
    border-color: var(--bs-table-border-color);
}
.cartimage {
  width : 96.5px;
  height: 55px;
}
.updatebtn {
  border-radius: 8px;
  background: #272223;
  border: 1px solid #43403E
}
.underline-link {
  text-decoration: underline;
}

@media (max-width: 575.98px) and (max-width: 767px) {
  header a.signbtn {
    margin-left: 125px;
  }

  #main h1 {
    font-size: 58px;
    text-align: center;
  }

  #main h6 {
    font-size: 17px;
  }

  .linksicons {
    margin-left: 175px;
  }

  footer a.nav-link {
    padding-right: 20px;
  }

  .dispnone {
    display: none;
  }
.canfirmcard {
  padding:20px  ;
  border: #ffffff solid 2px;
}
  .nfcbacklogo{
   
      top: 87%;
      width: 32%;
      /* color: white; */
 
  }
}
  /***********Shop Page *************/

  #shopvyldcard h1 {
    font-size: 39px;
  }

  #shopvyldcard h2,
  #faqs h2 {
    font-size: 33px;
  }

  #shopvyldcard .mboncard {
    left: 19%;
  }

  .f20 {
    font-size: 16px;
  }

  .mbmtp {
    margin-top: 195px !important;
  }

  .greybox {
    top: 0;
  }

  #faqs {
    margin-top: 30px;
  }

  .accordion-item {
    font-size: 13px;
  }

  #faqs .accordion-item h2,
  .accordion-flush .accordion-item .accordion-button,
  .accordion-flush .accordion-item .accordion-button.collapsed {
    font-size: 13px;
  }

  .accordion-button::after,
  .accordion-button:not(.collapsed)::after {
    font-size: 18px;
  }
/******************************** Thank you confirmation **************/

#thankyouconfirmation h5 {
  font-size: 20px;
}

#thankyouconfirmation h1 {
  font-size: 38px;
  color: #fff;
  font-style: italic;
  font-family: 'ABeeZee';
}

#thankyouconfirmation h2 {
  font-size: 24px;
  color: #fff;
   font-family: 'ABeeZee';
}

.fntabzee {
  font-family: 'ABeeZee';
}

#tablesec .table  {
  border: 1px solid #3C393A;
  padding: 10px;
}

#tablesec th {
  background-color: #272223 !important;
}

#tablesec tr {
  height: 70px;
  vertical-align: middle;
  background-color: #454343 !important;
}

#tablesec .table>:not(caption)>*>*{ 
  background-color: #211F1E;
  color: #fff;
  border-bottom: 1px solid #3C393A;
}






@media (max-width: 575.98px) and (max-width: 767px) { 

    header a.signbtn {
      margin-left: 125px;
    }

    #main h1 {
      font-size: 58px;
      text-align: center;   
      }

      #main h6 {
        font-size: 17px;  
      }

      .linksicons {
        margin-left: 175px;
      }

      footer a.nav-link {
         padding-right: 20px;
      }

      .dispnone {
        display: none;
      }


  /***********Upload design page ***************/

  #logindetails a {
    padding: 0;
  }

  #uploadesigncard .card {
    border: 1px solid #454343;
    border-bottom: 0;
  }

  #vyldfyrecard .bordotted {
    top: 12px;
    left: 11px;
  }

  #personalcard .vldcardbg {
    padding: 20px;
  }

  #personalcard .wifi {
    font-size: 15px;
  }

  #dashbrdetails ul li:first-child {
    padding-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #main h1 {
    font-size: 50px;
  }

  #main h6 {
    width: 60%;
    font-size: 17px;
  }

  .headingtxt {
    top: 10%;
  }

  .dispnone img {
    margin-top: 160px;
  }
}
