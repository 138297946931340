@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee");
@import url("https://fonts.googleapis.com/css2?family=Syne");
@import url("https://db.onlinewebfonts.com/c/060e116a70e3096c52db16f61aaab194?family=Sofia+Pro+Regular");

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  /* font-family: 'Poppins', sans-serif; */
  font-size: 16px;
  line-height: 22.4px;
  color: #ddd;
  background: #101010;
}
/*header a.navbar-brand, header a:hover.navbar-brand{
    font-family: 'Syne';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #FFF;
    width: 222px;
    height: 36px;
}*/

.menu-btn i {
  background-color: #000;
  color: #ffb400;
  padding: 10px;
  position: relative;
  left: -30px;
  top: 35%;
  z-index: 100;
  font-size: 20px;
  border-radius: 5px;
}

header a.navbar-brand svg {
  position: relative;
  top: -30px;
  width: 231px;
}
@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: scroll;
  }

  .nav-elements.active {
    width: 270px;
    margin-top: 40px;
    margin-right: 10px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 6px;
  }
}

header .navbar-nav a.nav-link {
  color: #8b8b8b;
  font-family: "ABeeZee";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 3px;
  border: 1px solid transparent;
  padding: 8px;
}

header .navbar-nav a.nav-link:hover,
.navbar-nav .nav-link.active {
  border-radius: 3px;
  /* border: 1px solid #FFB400; */
  color: #f8f8f7;
  /* padding: 8px; */
}
.navbar-nav .nav-link.active-nav {
  border-radius: 3px;
  /* border: 1px solid #FFB400; */
  color: #4a4a48;
  /* padding: 8px; */
}
header .loginbtn {
  border-radius: 4px;
  border: 1px solid #8b8b8b;
  color: #8b8b8b;
}
.loginbtn:hover {
  background-color: #ffb100;
 }

main {
  background: #1e191c;
}

h1 {
  color: #fff;
  font-family: "Syne";
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h2 {
  color: #fff;
  font-family: "Syne";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

h3 {
  color: #fff;
  font-family: "Syne";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.fnt18 {
  color: #ddd;
  font-family: "ABeeZee";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.f18 {
  font-size: 18px;
}

.f11 {
  font-size: 11px;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f96 {
  font-size: 96px;
  font-family: "Syne";
  letter-spacing: 0px;
}

.ff14 {
  font-size: 14px;
  font-family: "Syne";
  letter-spacing: 0px;
}

.line {
  border-top: 1px solid #3a3a3a;
  /* width: 90%;  margin: 0 auto; */
}

.herobg {
  background: url("../src/images/hero-Image-bg.png");
  background-size: cover;
  min-height: 800px;
}

.howitworks {
  background: url("../src/images/hero-Image-bg.png");
  background-size: cover;
}

.arrow {
  position: absolute;
  top: 40%;
  left: 5%;
  height: auto;
}

.banner {
  position: absolute;
  top: 2;
  left: 20%;
}

.btnyellow {
  color: #fff;
  border: 1px solid #8b8b8b;
  border-radius: 8px;
  font-family: "Sofia Pro Regular";
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  padding: 16px 30px;
  justify-content: center;
  align-items: center;
}
.welcard .passwordToggle {
  position: absolute;
  /* top: 21%; */
left: 90%; /* Adjust the distance from the right edge */
  /* transform: translateY(-50%); */

  cursor: pointer;
}
.small-round-button {
  position: absolute;
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  border-radius: 50%; /* Makes the button circular */
  background-color: #fafbfc; /* Background color */
  color: #fff; /* Text color */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; /* Remove underlines for links */
  cursor: pointer; /* Show pointer cursor on hover */
}

/* Add hover effect */
.small-round-button:hover {
  background-color: #ffb400; /* Change background color on hover */
}
.validationwarning {
  color: red;
  align-items: start;
  font-size: 13px;
  font-weight: 50px;
  margin-left: -300px;
}
.btnyellow:hover {
  background: #ffb400;
  color: #101010;
}

.btnbuy {
  border-radius: 8px;
  color: #fff;
  border: 2px solid #ffb400;
  background: #fff;
  color: #000;
  font-family: "ABeeZee";
  font-size: 16px;
  font-weight: 400;
  display: inline-flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
}

.bgblack {
  background: #101010;
}

.btnbuy:hover {
  color: #000;
  background: #ffb400;
}

.fntfamsof {
  font-family: "Sofia Pro Regular";
}

.fntfamabz {
  font-family: "ABeeZee";
}

.fnrfamsyn {
  font-family: "Syne";
}

.h4 {
  font-size: 24px;
  font-weight: 700;
}
.socialsharecard {
  border: 2px solid #EEEEEE ; 
  padding :  20px;

}
.iconbox {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(
    102deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(10px);
  padding: 6px;
}
.modal-button-apply {

    border: 0px;
    background: black;
    color: white;
    border-radius: 5px;
    width: 100%;

}
.modal-button {

 width:240px;
  border-radius: 5px;
 

}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: 0px solid var(--bs-modal-header-border-color) !important;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
a.linkyellow1 {
  color: #ffb400;
  text-decoration: none;
}

#yellowbanner {
  margin-top: -80px;
  background: #ffb400;
  height: 60px;
}

#visacards .card {
  background: none;
  border: 0;
  color: #fff;
}
.block-picker :hover {
  position: relative;
  /* transform: translateX(-50%); */
  /* left: 50%; */

  /* z-index: 2;  */

}
.product-back {
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPEAAADqCAYAAABz2qNRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAO+SURBVHgB7djhiR1VGIDhM3PvuskGJSUoWIAFGBmDfSSdJNuBHWj6kOViLMAKJFiBCOsao3ecCSE17AvP82OGM8zfl++cM40PluXr5TCmF2NMX23LxwO4p9bTOsarm9PrH/fVtD++W7558d/5/PL27u3Y3mNd1wHcTxfH47h6cDmmeXp5c/r5enq6PHm+ntcf/ry9Ey9ETNM0Pnt0NdZ5/XbeRvGzv97+I2AI2Xu9vft77EfgeWt6effu3wG07Eff/Q5rHkDSh93zYxFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxxIoY4EUOciCFOxBAnYogTMcSJGOJEDHEihjgRQ5yIIU7EECdiiBMxxIkY4kQMcSKGOBFDnIghTsQQJ2KIEzHEiRjiRAxx8zrGH9M0DaBpnsb662E2kKHm4njcnutpj/j60cPLYRpDx97r1YPLse2kXx1+e/P7my+/+Hz65OK4nM/rOJ/PA7if9niPh8P49OrhmObp+ub0+vuP4/fp8uT5tni2/bYM4F56f4e1HYH3HfRPp19O+7f/AW2xXn8/ldcoAAAAAElFTkSuQmCC); */
  /* padding: 10px; */
  height: 234px;
  width: 241px;
  border-radius: 5px;
  background: #323232;
}
.translate-middle {
  transform: translate(-90%, -63%) !important;
}
.qrcode {
  
    position: relative;
    width: 30%;
    margin-left: 302px;
    margin-top: -612px;

}
#image-container {
  margin-top: 20px;
}
.highlight {
  background-color: #f0f0f0; /* Example highlight color */
  border: 2px solid #FFB400 !important
  ; /* Example border color */
}
.social-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
}

.social-button {
  display: inline-block;
}

.social-link {
  display: inline-block;
  width: 100%;
  text-align: center;
}

#platform-image {
  width: 100%;
  position: absolute;
  margin-left: 234px;
  margin-top: -665px;
  z-index: 99;
  max-height: 660px;
  max-width: 390px;
}
.alert {
  position: fixed;
  top:10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1100;
  padding: 0.8rem;
  opacity: 0.9;
  content: '\2713';
  background: #34A853;
  border-color: #189B0C;
  color: #fff;
}
.alert-danger {
  content: 'f00d'; 
  background: #FF3443;
  border-color: #DA0000;
  color: #fff;
}
.alert-info {
  content: 'ℹ';
  background: #40D3DD;
  border-color: #25A1AA;
  color: #fff; 
}
.icon-and-message {
  display: flex;
  align-items: center;
}

.icon-and-message .message {
  margin-left: 10px; /* Adjust spacing between icon and message */
}

.icon-and-message svg {
  margin-right: 10px; /* Adjust spacing between icon and message */
}
.paterwaste {
  border-radius: 15px;
  background: #ffb400;
  color: #000;
}


.heightbg {
  position: absolute;
  top: 65%;
  height: 75%;
  width: 100%;
  z-index: -1;
}

h5.modal-title {
  color: #000;
  font-family: "ABeeZee";
  font-size: 20px;
}

.profile-pic img {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(217, 217, 217, 0.62);
  padding: 40px;
}
.accordion {
  /* --bs-accordion-color: var(--bs-body-color); */
  /* --bs-accordion-bg: var(--bs-body-bg); */
  /* --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease; */
  /* --bs-accordion-border-color: var(--bs-border-color); */
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  /* --bs-accordion-btn-color: var(--bs-body-color); */
  /* --bs-accordion-btn-bg: var(--bs-accordion-bg); */
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23052c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #86b7fe;
  /* --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  /* --bs-accordion-active-color: var(--bs-primary-text-emphasis); */
  /* --bs-accordion-active-bg: var(--bs-primary-bg-subtle); */
}
.accordion-button {
  /* position: relative; */
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x); */
  font-size: 1rem;
  color: #ffb400 !important;
  text-align: left;
  background-color: #101010 !important;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: none !important;
   box-shadow: #101010 !important ;
}
.overlaylinks a {
  color: #101010;
  font-family: ABeeZee;
  font-size: 14px;
  text-decoration: none;
}

.square {
  width: 50px;
  height: 50px;
  background: #d9d9d9;
  margin: 0px 10px;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  line-height: 1.5;
  color: #ffffff;
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.input {
  background-color: #575757;
  color: white;
  margin-top: 15px;
}
#login .form-control {
  display: initial;
}
/*************** Zoom slider **************************/
/* range 2 */
.range-input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: #ccc;
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f50;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

.range-input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #f50;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

.range-input::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

.range-input:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.range-input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.range-input::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

.range-input:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.range-input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.value2,
.value3,
.value4 {
  font-size: 26px;
  width: 50px;
  text-align: center;
}

.leftlinks a,
.leftlinks a svg {
  color: #ffb400;
  font-family: "ABeeZee";
  font-size: 14px;
  text-decoration: none;
}

.leftlinks a:hover,
.leftlinks a svg:hover {
  color: #fff;
}

.copyurl {
  border-radius: 100px;
  opacity: 0.21;
  background: #d9d9d9;
}

.form-select,
.modal-content input.form-control {
  position: relative;
  border-radius: 6px;
  opacity: 0.21;
  background: #211f1e;
  color: #ffffff;
}

#profile {
  font-size: 14px;
  font-family: "ABeeZee";
}
 /* dashboard css */
 #dashbrdetails ul li {
  border-right: 1px solid #454343;
  padding: 0px 30px;
  height: 18px;

}

#dashbrdetails ul li:last-child {
  border-right: 0px solid #454343;
  padding-right: 0px;
}

#dashbrdetails a {
  color: #fff;
}

.dashboard  ul.nav li  {
  padding: 10px 0px;
}

.dashboard  ul.nav li a {
  color: #fff;
  padding: 10px 0px;
}




#profile textarea.wthbor {
  border-radius: 7px;
border: 1px solid #615A5B;
background: #211f1e;
}

#profile a.acc {
  text-decoration: none;
  color: #ffb400;
  font-size: 12px;
}

.f10 {
  font-size: 10px;
}

.fcgry {
  color: #bcbcbc;
}
.custom-modal {
  background-color: black;
  color: white; /* Set text color to white or any color that suits your design */
}
.uploadimg {
  /* background: #D9D9D9; */
  color: #272223;
  width: 100%;
  min-height: 182px;
}
#profile .inputtext {
  color: rgb(253, 247, 247);
  font-size: 16px;
  background-color: #818181;
  font-family: ABeeZee ;
}
.input-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.input-container:focus-within label {
  color: #d9d9d9;
}
.basicDetails input {
  height: 40px;
  border-radius: 4px;
  background-color: #686868;
  border: none;
  padding: 5px 12px 4px 145px;
  font-size: 14px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
.scrolling-text {
  height: 200px; /* Set the fixed height for the container */
  overflow-y: auto; /* Enable vertical scroll if content overflows */
  border: 1px #ccc;
  padding: 5px;
}
.cropper-container {
  position: relative;
  width: 600px; /* Set the desired width */
  height: 235px; /* Set the desired height */
  border: 1px solid #ccc; /* Add a border for styling */
  overflow: hidden; /* Hide overflowing content */
}

.cropper-container img {
  width: 100%; /* Make the image fill the container */
  height: 100%; /* Make the image fill the container */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}
.input-container input {
  height: 40px;
  border-radius: 4px;
  background-color: #686868;
  border: none;
  width: 100%;
  padding: 5px 12px 0px 118px;
}
.form-item input:focus ~ label {
  top: 0!important;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #edff79;
}

.input-container label {
  position: absolute;
  padding: 2px 2px 0 10px ;
  /* width: 210px; */

  line-height: 3;
  /* left: 20px; */
}

#profile .input-group-text,
.modal .input-group-text {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 8px;
  border: 1px solid #969696;
  background: #969696;
  font-family: 'ABeeZee';
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding: 8px 12px;
}

#profile .input-group-text3
{
  position: absolute;
  right: 0;
  top:30px;
  border-radius: 8px;
  border: 1px solid #969696;
  background: #969696;
  font-family: 'ABeeZee';
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding: 8px 12px;
}

#profile .input-group-text2
 {
  position: absolute;
  right: 0;
  top: 31px;
  border-radius: 8px;
  border: 1px solid #969696;
  background: #969696;
  font-family: 'ABeeZee';
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding: 8px 12px;
}
#profile .mediaboxshw {
  border-radius: 7px;
  background: #272223;
  box-shadow: 0px 2px 4px 0px #43403e;
}

#profile .mediaboxout {
  border-radius: 7px;
  background: #818181;
}

#profile .mediabox {
  border-radius: 7px;
  background: #818181;
}

#profile .innrmediabox {
  border: 0px solid #454343;
  background: #211f1e;
  border-radius: 7px;
  color: #fff;
  /* max-width: 200px; */
}
#profile select.form-select   {
  color: #fff;
  opacity:inherit;
}
.image-display-card {
  /* width: 287px; */
  height: 287px;
  border-radius: 10px;
  /* 1:1 aspect ratio */
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.image-display {
  position: absolute;
  top: 10px;
  right: 10px;
  /* width: 287px; */
  height: 287px;
  background: none;
  border: 3px;
  border-radius: 10px;
  cursor: pointer;

  object-fit: cover;
}
.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #211F1E;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}
#profile .cambg {
  background-image:url("./images/Group206.png") ;
  background-size: cover;
  width: 100%;
  height: 148px;
  position: relative;
}

#profile .subtitle {
  color: #fff;
  font-family: Syne;
  font-size: 20px;
  font-weight: 700;
}

.cam {
  position: absolute;
  top: 10%;
  right: 2%;
  cursor: pointer;
}
.rounded-image-container {
  display: flex;
  
  justify-content: center;
  align-items: center;
  width: 109px; /* Set the desired width and height for your rounded image */
  height: 109px;
  border-radius: 50%; /* Makes the image round */
  overflow: hidden; /* Hides any overflow */
}
.rounded-image {
 
  width: 109px;
  height: 109px;
  object-fit: cover; /* Adjusts the image within the container */
}
.roundcam {
  position: absolute;
  /* background-image: url("./images/camara.png"); */
  top: 60%;
  left: 42%;
  border-radius: 100%;
  background: #fff;
  padding: 0px;
  cursor: pointer;
}

.delet svg {
  position: absolute;
  top: 10%;
  right: 3%;
  color: #000;
}

#profile .mobilebg {
  background: url("../src/images/mobilebg.png");
  background-size: cover;
  width: 100%;
  height: 80%;
  position: relative;
}

#profile .mobcont {
  position: absolute;
  top: 12%;
  left: 0;
  text-align: center;
}

#profile .mobcont .roundcam {
  border-radius: 100%;
  background: #fff;
  padding: 10px;
  width: 100px;
  height: 100px;
}
#preview .roundcam {
  top: -30% !important ;
  left: 38% !important ;

}
#preview .surname {
  padding-top: 40px;
  color: black;
}
.input--file {
  position: relative;
  color: #f7f4f4;
  cursor: pointer;
  right: 0;
  top: 0;
}
.image-card {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* 1:1 aspect ratio */
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 80px;
  max-width: 80px;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  max-height: 80px;
  max-width: 80px;
}

.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  /* background-color:#FFB400; */
  color: rgb(1, 1, 1);
  border: none;
  padding: 5px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.image:hover .delete-btn {
  opacity: 1; /* Show delete button on hover */
}
.input--file span {
  cursor: pointer;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

footer a.links {
  color: #8b8b8b;
}

.bottomlink {
  position: fixed;
  bottom: 13%;
  left: 6.2%;
}

.bottomlink a {
  color: #fff;
  font-size: 14px;
}

#sidebar-nav {
  width: 245px;
  position: fixed;
  height: 100%;
  left: 0;
  background: #1e191c;
}

/*main {
  margin-left: 250px;
}*/

/* Sidebar Styles */

.sidebar-nav {
  width: 250px;
  margin: 0 0 0 10px;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 15px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #ffb400;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
}

.sidebar-nav li a.active {
  text-decoration: none;
  color: #fff;
}

.modal-backdrop.show {
  opacity: 0.7;
}

/*
.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.no-margin {
  margin: 0;
}

@media (min-width: 768px) {
  #wrapper {
     padding-left: 250px;
  }
  .fixed-brand {
     width: 250px;
  }
  #wrapper.toggled {
     padding-left: 0;
  }
  #sidebar-wrapper {
     width: 250px;
  }
  #wrapper.toggled #sidebar-wrapper {
     width: 250px;
  }
  #wrapper.toggled-2 #sidebar-wrapper {
     width: 62px;
  }
  #wrapper.toggled-2 #sidebar-wrapper:hover {
     width: 250px;
  }
  #page-content-wrapper {
     padding: 20px;
     position: relative;
     transition: all 0.5s ease;
  }
  #wrapper.toggled #page-content-wrapper {
     position: relative;
     margin-right: 0;
     padding-left: 250px;
  }
  #wrapper.toggled-2 #page-content-wrapper {
     position: relative;
     margin-right: 0;
     margin-left: -187px;
     transition: all 0.5s ease;
     width: auto;
  }
}*/

/************************ Sign in ***********************/

#signin .card {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(
    102deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  backdrop-filter: blur(10px);
  color: #fff;
}
.input {
  border-radius: 2.5px;
  border: 1px solid #575757;
  color: white;
  font-size: 14px;
  background: #575757;
  font-family: "ABeeZee";
  padding: 15px;
  margin-left: 45 px;
  width: 450px;
  height: 40px;
}
.social-media-card {
  display: flex;
  border: 0px solid #454343;
  background: white;
  border-radius: 3px;
  color: #fff;
  /*width: 450px ;*/
}


#profile .midcontainer {
  min-height: 755px;
}
.social-media-item {
  padding: 10px;
  align-items: center;
  font-size: 14px;
  font-family: 'ABeeZee';
}


.social-media-logo {
  width: 24px; /* Adjust the logo size as needed */
  height: 24px;
  border-radius: 0%;
  margin-left: 0px;

  /* Adjust spacing between logo and name as needed */
}

.social-media-name {
 /*margin-left: 70px;*/
  align-items: center;
  color: #0a0909;
  padding:2px 16px 0px 0px;
}
.loginbox {
  max-width: 564px;
   font-family:  "poppins", sans-serif;
  background: #222427 !important;
  margin: 40px auto 80px auto;
  padding: 30px 45px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}

#signin .form-control::placeholder,
#profile .form-control::placeholder,
#signin input[type="password" i],
#signin input[type="email" i],
#signin input[type="text" i],
#profile input[type="text" i],
#profile input[type="email" i],
#profile input[type="password" i] {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(251, 245, 245);
  display: initial;
  size: 1cap;
  background-color: #575757;
  border-radius: 8px;
  border-color: #454343;
}
#profile .form-select {
  border-color: #211f1e;
  padding: 5px 0px 0px 0px;
}
#profile input[type="text" i],
#profile input[type="email" i],
#profile input[type="password" i] {
  background-color: #211f1e;
}
.modal-header .btn-close {
  color: #ffb100;
  opacity: 1;
  background-image: none;
  font-size: 40px;
}

.modal-backdrop {
  opacity: 0.8;
}

.rundbtn {
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #fff;
  font-family: "ABeeZee";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 15px 28px;
  width: 302px;
}

a.linkyellow {
  color: #ffb400;
  text-decoration: none;
  position: relative;
  top: 0;
}

a.linkwhite {
  color: #fff;
  text-decoration: none;
}
/* .linkicon {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; 
    
  
} */
a.linkicon {
  background-image: url("./images/backgrond_icon.png");
  height: auto; 
  display:inline-block;
  color : #969696;
  text-decoration: none;
 
}
img .linkicon {
  color: #969696;
  text-decoration: none;
}
.linkicon :hover {
  background-image: url("./images/yellowbackgroundicon.png");
  background: #ffb400;
  color: white;
  text-decoration: none;
  transition: all 0.5s;
  
}
.eye {
  position: relative;
  left: 93%;
  top: -40px;
  cursor: pointer;
}
.css-1nmdiq5-menu{
  background-color: black;
}
.greybg {
  background: #575757;
}

.container-color svg {
  position: relative;
  z-index: 1;
  margin-left: 50px;
}


/* .bigmob {
  background:  url('../images/mobilebg.png') ;
  background-size: cover;
  width: 508px;
  height: 100%;
 } */
 .innrmediabox2 {
  border: 0px solid #454343;
   background: white; 
  border-radius: 3px;
  color: #fff;
  font-family: 'ABeeZee';
  /* max-width: 200px; */
}
.innrmediabox {
  border: 1px solid #454343;
  background: white;
  border-radius: 8px;
  color: #fff;
  font-family: 'ABeeZee';
  /* max-width: 200px; */
}
.css-13cymwt-control {
  /* background-color: #211F1E; */
  padding: 10px;
  border-color:rgb(112, 110, 110) !important;
  border: #312e2e !important ;
  border-radius: 8px;
  background-color: #211F1E !important ;
}
pre {
  font-family: 'Courier New', Courier, monospace;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reactselect {
  background-color: #211F1E;
  padding: 10px;
  border: #312e2e;
  border-radius: 8px;
}

.mediabox {
  border: 1px solid #454343;
   background: #211F1E; 
  border-radius: 3px;
  color: #fff;
  /* max-width: 200px; */
}
.mobilecoverpage {
  border: 1px solid #454343;
  background: white;
  border-radius: 20px;
  overflow-y: auto !important;
  color: #fff;
  padding: 12px;
  font-family: 'ABeeZee';
  top: 100px;
}

/* Hide scrollbar for all browsers */
.mobilecoverpage::-webkit-scrollbar {
  display: none;
}

.mobilecoverpage {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.color-selector {
   /* Position the container relative to the viewport */
  top: 444px; /* Adjust as needed */
  left: 170px; /* Adjust as needed */
  z-index: 999;
}

.inmob {
  height: 600px;
}

.mobilecont {
  position: fixed;
  width: 305px;
}
.mobilecont1{
  width: 100%
}
.innrmediabox .card{
  background: none;
  border-radius: 20px;
  /* max-width: 131px; */
}
/* .innrmediabox .opencard{
  background: none;
  border-radius: 20px;
  /* max-width: 131px; */
 
.mediaboxshw .card:hover {
  border-radius: 20px;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  box-shadow: 2px 2px 4px 0px #ffb400;
  /* min-width: 131px; */
}

.picture-container {
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  /* min-width: 131px; */
}

.picture {
  border-radius: 20px;
  border: 2px solid #969696;
  height: 241px;
  /* width: 131px; */
  transition: all 0.5s ease-in-out;
  position: relative;
}

.picture .hover a {
  border: solid #969696;
  background: #969696;
  color: #fff;
  font-family: "ABeeZee";
  font-size: 12px;
  padding: 2px;
  width: 75%;

  position: absolute;
  bottom: 5%;
  left: 10%;
}

.picture-src {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.mobbackg {
  width: 100%;
  border-radius: 20px;

  border: 2px solid #969696;
  min-height: 241px;
  /* max-width: 131px; */
  transition: all 0.5s ease-in-out;
  display: flex;
  align-content: center;
  justify-content: center;
}

.mobgrad {
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(180deg, #020d0b 0%, #a09989 100%);
  border: 2px solid #969696;
  min-height: 241px;
  /* max-width: 131px; */
  transition: all 0.5s ease-in-out;
  display: flex;
  align-content: center;
  justify-content: center;
}

.mobbackg .hover,
.mobgrad .hover {
  /* opacity: 0; */
  display: flex;
  justify-content: end;
  flex-flow: column;
  transition: all 0.5s ease-in-out;
  padding-bottom: 15px;
  font-size: 20px;
  color: #fff;
}

.picture .hover {
  opacity: 0;
  display: flex;
  justify-content: end;
  flex-flow: column;
  transition: all 0.5s ease-in-out;
}
.roundadd {
  position: absolute;
   border-radius: 100%;
    padding: 7px;
    right:24px;
  cursor: pointer;
}

.mobbackg .hover i,
.mobgrad .hover i {
  position: relative;
  left: -39%;
}

.mobbackg:hover .hover,
.image-card:hover .small-round-button,
.mobgrad:hover .hover,
.picture:hover .hover {
  opacity: 1;
  cursor: pointer;
}

input[type="color"] {
  position: relative;
  left: 4.5%;
  top: 28%;
  z-index: 2;
  opacity: 0;
}

.coverImage {
  /* border-radius: 60px 60px 3px 3px; */
  /* min-height: 211px; */
  height: auto;
  width: 100%;
  position: relative;
  background-size: cover;
}
.coverpage {
  border: 0px solid #969696;
  min-height: 90px;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.coverpage1 {
  border-radius: 60px 60px 3px 3px;
  border: 10px solid #969696;
  background: url("../src/images/graphic.png") center no-repeat;
  min-height: 211px;
  width: 100%;
  position: relative;
  background-size: cover;
}

.profilepic {
  position: absolute;
  top: -7%;
  left: 40%;
}

.contpage {
  border: 0px solid #0a0909;
  border-top: 0px solid #969696;
  min-height: 120px;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'ABeeZee';
  font-size: 14px;
}

.contpage1 {
  border-radius: 3px 3px 60px 60px;
  border: 10px solid #969696;
  border-top: 0px solid #969696;
  background-color: #ffb400;
  color: #000;
}

.contpage1 h4, .contpage h4 {
  font-size: 24px;
  font-family: "ABeeZee";
  font-size: 24px;
  font-weight: 700;
}

.social-icons img {
  width: 30px;
  border-radius: 5px;

  border: 2px;
  height: 30px;
}

.mobcont {
  position: absolute;
  top: 15%;
  left: 0;
}

.mobilecont .cov {
  position: absolute;
  top: 10%;
  left: 40%;
  text-align: center;
}
.mobilecont1 .cov {
  position: absolute;
  top: 10%;
  left: 40%;
  text-align: center;
}
.mobilecont .roundcam {
  /* position: absolute; */
  top: -26%;
  left: 31%;
  border-radius: 60px;
  background: #fff;
  color: #c8c8c8;
  font-family: "ABeeZee";
  font-size: 39.24px;
}
.mobilecont1 .roundcam {
  /* position: absolute; */
  top: -60px;
  left: 35%;
  border-radius: 60px;
  background: #fff;
  color: #c8c8c8;
  font-family: "ABeeZee";
  font-size: 39.24px;
}
.l-navbar {
  position: fixed;
}

/* .custom-file-input label:hover,
.custom-file-input label:active {
  background-color: #2980b9;
} */
.mobilecont .surname {
  padding-top: 40px;
  color: black;
}
.mobilecont1 .surname {
  padding-top: 40px;
 
  margin-top: -40px;
  color: black;
  color: black;
}
.imagebox {
  width: 100%;
  /* height: 120px; */
}
.mobilecont .box {
  width: 100%;
  height: 140px;
  background: #fff;
  overflow-y: auto;
}
.mobilecont1 .box {
  width: 100%;
  height: 140px;
  background: #fff;
  overflow-y: auto;
}
.prevbtn {
  border-radius: 8px;
  /* border: 1px solid #969696; */
  background: #969696;
  padding: 15px 0;
  color: #fff;
  font-size: 14px;
  width: 100%;
}
.publicbtn {
  border-radius: 8px;
  border: 1px solid #43403e;
  background: #272223;
  padding: 10px 0px;
  color: #fff;
  font-size: 14px;
  width: 100%;
}

.prevbtn:hover {
  border: 1px solid #ffb400;
  background: #fff;
  color: #000;
}

/*********** Dropdown **************************************/

/* Dropdown styles for work page  */
.wrap-drop {
  border-radius: 8px;
  background: #575757;
  cursor: pointer;
  padding: 15px;
  position: relative;
  width: 100%;
  z-index: 3; 
}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label::after {
  color: rgba(var(--bs-body-color-rgb),.65);
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  position: relative;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  color: #bcbcbc;
}
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color:#211F1E;
  color: #bcbcbc;

  border-radius: var(--bs-border-radius);
}
.wrap-drop::after {
  border-color: #fff transparent;
  border-style: solid;
  border-width: 10px 10px 0;
  content: "";
  height: 0;
  margin-top: -4px;
  position: absolute;
  right: 1rem;
  top: 50%;
  width: 0;
}

.wrap-drop .drop {
  background: #fff;
  display: none;
  left: 0;
  list-style: none;
  margin-top: 0;
  opacity: 0;
  padding-left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 48px;
  z-index: 2;
}

.wrap-drop span {
  font-size: 16px;
}

.wrap-drop.active::after {
  border-width: 0 10px 10px;
}

.wrap-drop.active .drop {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

/*******Work Page css************/

#themesel ul {
  list-style: none;
  border-radius: 10px;
  background: #575757;
  margin-top: -3px;
}

#themesel ul li {
  padding: 12px 20px;
  border-bottom: 1px solid #656565;
}

#themesel ul li a {
  color: #fff;
  display: block;
}

#themesel .btn-flex {
  display: flex;
  align-items: stretch;
  align-content: stretch;
}

#themesel .btn-flex .btn:first-child {
  flex-grow: 1;
  text-align: left;
}

#themesel ul li:hover,
#themesel ul li a:hover,
#themesel ul li.active,
#themesel ul li.active a {
  color: #000;
  background-color: #ffb400;
  cursor: pointer;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#themesel .dropdown-toggle-split {
  border: 1px solid #656565;
}

#themesel ul.dropdown-menu {
  transform: translate(0px, 40px) !important;
  border-radius: 0;
}

.pagelinks a {
  color: #8b8b8b;
  font-size: 18px;
  font-family: ABeeZee;
  padding: 0px 12px 0px 12px;
  text-decoration: none;
}

.pagelinks a:hover {
  color: #ffb400;
}

.modal-content {
  background: none;
  box-shadow: none;
  border: 0;
}

.verifybutton {
background: black;
padding : 12px 23px;
}
/********************** Overlay **************************/
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1464f4;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: 0.3s ease;
}

.wrapper:hover .overlay {
  width: 100%;
}

.overlay h2 {
  text-transform: capitalize;
  font-size: 24px;
  color: #ffffff;
  width: 80%;
  margin: 50px auto;
  border: 1px solid #ffffff;
  text-align: center;
  padding: 20px;
}

.bushrstabs {
  width: 100%;
  font-size: 14px;
  color: #fff;
}

.bushrstabs .nav-tabs {
  border: 0;
}

.bushrstabs .tab1 {
  border-radius: 7px 0px 0px 7px;
  background: rgba(150, 150, 150, 0.25);
  color: #fff;
  border: 0;
}

.bushrstabs .tab2 {
  background: rgba(150, 150, 150, 0.25);
  color: #fff;
  border: 0;
  border-radius: 0;
}

.bushrstabs .tab3 {
  border-radius: 0px 7px 7px 0px;
  background: rgba(150, 150, 150, 0.25);
  color: #fff;
  border: 0;
}

.bushrstabs .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: 0;
}

.bushrstabs .togglebox {
  border-radius: 7px;
  background: #818181;
}

.nav-tabs .nav-link.active {
  background: #969696;
  color: #fff;
}

.gallimg img {
  height: 100px;
}

.bushrstabs .form-check-input:checked {
  background-color: #75f967;
  border-color: #75f967;
  width: 32px;
  height: 16px;
}

.bushrstabs input.time {
  background: none;
  border: 0;
  border: 0;
  color: #fff;
  width: 72px;
}

.bushrstabs input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

.bushrstabs input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

/**************************************************************/

.wrapper {
  width: 100%;
}

#sidebar {
  min-width: 270px;
  max-width: 270px;
  background: #1e191c;
  color: #fff;
  transition: all 0.3s;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

#sidebar.active ul.components li {
  font-size: 14px;
}

#sidebar.active ul.components li a {
  padding: 10px 0;
}

#sidebar.active ul.components li a span {
  margin-right: 0;
  display: block;
  font-size: 24px;
}

#sidebar.active .logo {
  padding: 10px 0;
}

#sidebar.active .footer {
  display: none;
}

#sidebar .logo {
  display: block;
  color: #fff;
  font-weight: 900;
  padding: 10px 30px;

  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  #sidebar .logo {
    transition: none;
  }
}

#sidebar ul.components {
  padding: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  #sidebar ul.components {
    transition: none;
  }
}

#sidebar ul li {
  font-size: 16px;
}

#sidebar ul li > ul {
  margin-left: 10px;
}

#sidebar ul li > ul li {
  font-size: 14px;
}

#sidebar ul li a {
  padding: 10px 30px;
  display: block;
  color: #ffb100;
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
}

#sidebar ul li a span {
  margin-right: 15px;
}

@media (max-width: 991.98px) {
  #sidebar ul li a span {
    display: block;
  }
}

#sidebar ul li a:hover {
  color: #fff;
}

#sidebar ul li.active > a {
  background: transparent;
  color: #fff;
}

@media (max-width: 991.98px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;
  }

  #sidebar.active {
    margin-left: 0 !important;
  }
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.accordion-button:not(.collapsed), .accordion-button:focus {
  box-shadow : none ;
}
.accordion-button::after, .accordion-button:not(.collapsed)::after {
  background-image: none;
}
@media (max-width: 991.98px) {
  #sidebarCollapse span {
    display: none;
  }
}

.footer {
  padding: 0 30px;
}

@media (max-width: 991.98px) {
  .footer {
    display: none;
  }
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  transition: all 0.3s;
}

.btn.btn-primary {
  background: #ffb100;
  border-color: #ffb100;
  color: #000;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
  background: #ffb100 !important;
  border-color: #ffb100 !important;
}

main {
  width: 100%;
  /* padding: 200px */
}
.link {
  display: flex;
  color: #ffb400;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link1 {
  display: flex;
  color: #ffb400;
  padding: 3px 20px;
  grid-gap: 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link1:hover {
  background: black;
  color: white;
  transition: all 0.5s;
}
.link:hover {
  background: black;
  color: white;
  transition: all 0.5s;
}
.active {
  background: #1e191c;
  color: white;
}
.active-nav {
  background: #1e191c;
  color: rgb(112, 110, 110);
}
.sidebar {
  width: 210px;
  -moz-transition: width 0.5s ease-out;
  -o-transition: width 0.5s ease-out;
  -webkit-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
  display: inline-block;
  z-index: 0;
}

.nav-sidebar {
  background: none;
  margin-top: 5px;
}

.sidebar ul li a span.fa {
  font-size: 20px;
}

.menuText {
  left: 50px;
  opacity: 1;
  -moz-transition: opacity 0.6s 0.2s ease-out;
  -o-transition: opacity 0.6s 0.2s ease-out;
  -webkit-transition: opacity 0.6s 0.2s ease-out;
  transition: opacity 0.6s 0.2s ease-out;
  position: absolute;
}

.collapseToggle {
  padding-left: 4px;
  display: block;
  cursor: pointer;
  padding-right: 5px;
}

#toggleIcon {
  -moz-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  -webkit-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
}

.rotate {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transition: transform 0.4s ease-out;
  -o-transition: transform 0.4s ease-out;
  -webkit-transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
}

.sidebar--Collapse {
  width: 55px;
  -moz-transition: width 0.6s ease-out;
  -o-transition: width 0.6s ease-out;
  -webkit-transition: width 0.6s ease-out;
  transition: width 0.6s ease-out;
}
.sidebar--Collapse .menuText {
  opacity: 0;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  position: absolute;
}



.form-item{
  position: relative;
  margin-bottom: 15px
}
.form-item input{
  display: block;
  width: 100%;
  height: 60px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all .3s ease;
  border-radius: 8px;
  padding-left: 11px;
 }

 .form-item input:focus{
  border: solid 1px #ccc !important;
}

 .form-item textarea{
  display: block;
  width: 100%;
  height: 100px;
  background: transparent;
  border: solid 1px #ccc;
  transition: all .3s ease;
  border-radius: 8px;
  padding-left: 5px;
 }

.form-item label{
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 8px;
  left: 2px;
  font-size: 16px;
  background: transparent;
  padding: 0 10px;
  color: #fff;
  transition: all .3s ease
}
.form-item input:focus + label,
.form-item input:valid + label,
.form-item textarea:focus + label,
.form-item textarea:valid + label,
.form-item input[type="email" i]:focus + label,
.form-item input[type="email" i]:valid + label
{
  font-size: 12px !important;
  top: 5px;
  color: #fff;
 
}

.social-media-card a {
  color: #000;
  font-family: 'ABeeZee ';
  font-size: 14px;
  width: 100%;
}

.contpage p, .mobilecont .surname {
  font-size: 14px;
  font-family: 'ABeeZee';
}

.contpage h4 {
  padding-top: 20px;
}




@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) and (max-width: 991.98px) {
  .sidebar-nav {
    width: 250px;
    margin: 0 0 0 10px;
    padding: 0;
    list-style: none;
  }

  .roundcam {

    left: 39%;

}
  

  .mobilecont {
    position: inherit;
    width: 100%;
}

  .nav-links {
    flex-direction: row;
    position: static;
    background-color: transparent;
    display: flex;
  }
  .bottomlink {
    position: fixed;
    top: 3%;
    left: 60%;
    border: none;
  }

  .bottomlink {
    position: absolute;
    top: 16px;
    left: 83%;
    border: none;
  }
  .bottomlink a {
    color: #fff;
    font-size: 15px;
  }
  #profile .input-group-text,
  .modal .input-group-text {
    position: absolute;
    right: 0;
    border-radius: 8px;
    border: 1px solid #969696;
    background: #969696;
    font-family: 'ABeeZee Bold';
    font-size: 14px;
    color: #fff;
  }
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
    background-color: #000000a8;
}


  .navbar-nav .nav-link.active {
    border-radius: 3px;
    border: none;
    border-bottom: 3px solid #ffffff;
    color: #ffffff;
    /* padding: 3px; */
  }
  .navbar-nav .nav-link.active-nav {
    border-radius: 3px;
    border: none;
    border-bottom: 3px solid #ffffff;
    color: #ffffff;
    /* padding: 3px; */
  }
  .row-item {
    align-items: center;
    justify-content: center;
  }
  .link_text {
    color: #fff;

    font-family: ABeeZee;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  .icon {
    color: white;
  }
  .icon2 {
    color: #FFB400;
  }
  .navbar-nav {
    flex-direction: inherit;
  }
  .navbar {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-toggle {
    display: block;
  }
  .nav-link {
    margin-bottom: 3px solid;
  }
  .l-navbar {
    position: fixed;
  }
 
  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffb400;
    padding: 10px 10px;
    grid-gap: 15px;
    margin-left: 2px;
    transition: all 0.5s;
    border-bottom: 3px solid;
  }
  .horizontal-menu {
    flex-direction: row;
  }

  .l-navbar {
    position: inherit;
  }
  .smcenter {
    text-align: center;
  }

  


  /* mobile profilemenu */


 
  
  /* Navigation links */
  .navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    background: none;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  /* The subnavigation menu */
  .subnav  {
    float: left;
    overflow: hidden;
  }
   .link_text2 {
    color: #FFB400;
    font-family: ABeeZee;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}
  /* Subnav button */
  .subnav .subnavbtn {
    font-size: 14px;
    border: none;
    outline: none;
    color: white;
    padding: 13px 16px 14px 16px; 
    background: none;
    border-bottom: 3px solid #FFB400;
    margin: 0;
  }
  
  /* Add a red background color to navigation links on hover */
  .navbar a:hover, .subnav:hover .subnavbtn {
    background-color:transparent;
  }
  .subnav-content a.active {
    float: left;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 11px;
}
  /* Style the subnav content - positioned absolute */
  .subnav-content {
    display: none;
    position: absolute;
    left: 30px;
    background-color: transparent;
    width: 100%;
    z-index: 1;
  }
  

  .link2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffb400;
    padding: 10px 10px;
    grid-gap: 15px;
    margin-left: 2px;
    transition: all 0.5s;
    border-bottom: 3px solid #FFB400;
}
  /* Style the subnav links */
  .subnav-content a {
    float: left;
    color: #FFB400;
    text-decoration: none;
    font-size:11px;

  }
  
  /* Add a grey background color on hover */
  .subnav-content a:hover {
   
    color:#FFFFFF;
  }
  
  /* When you move the mouse over the subnav container, open the subnav content */
  .subnav:hover .subnav-content {
    display: block;
  }






}



/* For Large screens */
@media (min-width: 1500px) {
  .bottomlink {
    left: 11%;
  }
}

