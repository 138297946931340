/* @import url('https://db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text'); */
@import url('https://fonts.googleapis.com/css?family=Inter');
/* @import url('https://db.onlinewebfonts.com/c/7615af7a9f681395bf515be718f1bf9c?family=SofiaPro'); */
@import url('https://fonts.googleapis.com/css2?family=ABeeZee');


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,
sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,
footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a.linkyellow1 {
  color: #FFB400;
  text-decoration: none;
}

body {
  font-size: 14px;
  font-weight: 400;
  background: #1E191C;
  color: #fff;
}

header a.signbtn {
  border-radius: 3px;
background: #323232;
color: #fff;
padding: 5px 8px;
font-size: 16px;
font-weight: 600;
font-family: 'SF Pro Text';
}

#main h1 {
font-family: 'Inter';
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 68px; /* 106.25% */
letter-spacing: -4.16px;
}



#main h1 span {
  background: linear-gradient(90deg, #FFB400 0%, #FF8B00 55.53%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.headingtxt {
  position: absolute;
  top: 30%;
  left: 0;
}

#main h6 {
font-family: 'SF Pro Text';
font-size: 20px;
font-weight: 400;
}

#main a.probtn {
font-size: 16px;
font-weight: 600;
border-radius: 3px;
background: #323232;
padding: 16px 30px;
color: #fff;
font-family: 'SofiaPro';
}


/************ Login Page ******************/

.welcard {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 1px solid #43403E;
  background: #272223;
}

.googlecard {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top: 0px solid #43403E;
  border-left: 1px solid #43403E;
  border-right: 1px solid #43403E;
  border-bottom: 1px solid #43403E;
  background: #272223;
}


.welcard input.form-control {
  border-radius: 5px;
  position: relative;
border: 1px solid #454343;
background: #211F1E;
backdrop-filter: blur(10px);
color: white
}


.form-control::placeholder {
  color: #696867;
font-family: 'Inter';
}

.loginbtn {
  border-radius: 5px;
background: #FFF;
backdrop-filter: blur(10px);
color: #141517;
font-size: 16px;
font-weight: 600;
width: 100%;
display: block;
padding: 5px;
text-align: center;
}

.googlebtn {
  border-radius: 5px;
  background: #383433;
backdrop-filter: blur(10px);
color: #fff;
font-family: 'ABeeZee';
font-size: 14px;
font-weight: 600;
width: 100%;
display: block;
padding: 5px;
text-align: center;
}


footer .line {
 border-top: 1px solid #323232;
}

footer a.nav-link {
  color: #969696;
font-family: 'SF Pro Text';
font-size: 14px;
font-weight: 400;
letter-spacing: -0.91px;
padding: 10px 25px 10px 0px;
}

footer a:hover.nav-link, #main a:hover.probtn, header a:hover.signbtn  {
  color: #FFB400;
}

footer a.nav-link1 {
  color: #969696;
font-family: 'SF Pro Text';
font-size: 14px;
font-weight: 400;
letter-spacing: -0.91px;
padding-right:  0px;
}

footer a.nav-link i {
  font-size: 20px;
}




























@media (max-width: 575.98px) and (max-width: 767px) { 

    header a.signbtn {
      margin-left: 125px;
    }

    #main h1 {
      font-size: 58px;
      text-align: center;   
      }

      #main h6 {
        font-size: 17px;  
      }

      .linksicons {
        margin-left: 175px;
      }

      footer a.nav-link {
         padding-right: 20px;
      }

      .dispnone {
        display: none;
      }


  }

  @media screen and (min-width : 768px) and (max-width : 991px){

    
    #main h1 {
      font-size: 50px;   
      }

      #main h6 {
        width: 60%;
        font-size: 17px;
      }

      .headingtxt {
        top: 10%; 
    }

    .dispnone img {
      margin-top: 160px;
    }


  }